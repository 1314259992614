import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { // 404
    path:'*',
    name:'404',
    component: () => import( '@/views/notFund.vue' ),
  },
  {
    path:'/',
    redirect:localStorage.getItem('userInfo') ? localStorage.getItem('activePath') : '/login'
  },
  { // 登录
    path:'/login',
    name:'登录',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Login.vue'),
  },
  { // 主页
    path:'/home',
    name:'主页',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Home.vue'),
    children:[
      { // 小程序用户管理
          path: '/appletUser',
          name:'小程序用户管理',
          component: () => import('@/views/appletUser/index.vue'),
          children:[]
      },
      { // 检测记录
        path: '/detectionRecords',
        name:'检测记录',
        component: () => import('@/views/detectionRecords/index.vue'),
        children:[]
      },
      { // 柜机列表
        path: '/cabinetList',
        name:'柜机列表',
        component: () => import('@/views/cabinetList/index.vue'),
        children:[]
      },
      { // 柜机列表 - 系统详情
        path: '/cabinetList/systemDetail',
        name:'系统详情',
        component: () => import('@/views/cabinetList/system/index.vue'),
        children:[]
      },
      { // 柜机列表 - 设备详情
        path: '/cabinetList/equipDetail',
        name:'设备详情',
        component: () => import('@/views/cabinetList/system/equipDetail.vue'),
        children:[]
      },
      // 模板管理
      { // 设备模板
        path: '/template/equipTemplate',
        name:'设备模板',
        component: () => import('@/views/templateManage/equipTemplate/index.vue'),
        children:[]
      },
      { // 流水线模板
        path: '/template/assemblyLineTemplate',
        name:'流水线模板',
        component: () => import('@/views/templateManage/assemblyLineTemplate/index.vue'),
        children:[]
      },
      { // 动作模板
        path: '/template/actionTemplate',
        name:'动作模板',
        component: () => import('@/views/templateManage/actionTemplate/index.vue'),
        children:[]
      },
      // 权限管理
      { // 用户管理
          path: '/rights/user',
          name:'用户管理',
          component: () => import( '@/views/authorityManage/usersManage/index.vue'),
      },
      { // 角色管理
          path: '/rights/role',
          name:'角色管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/authorityManage/rolesManage/index.vue'),
      },
      { // 菜单管理
        path: '/rights/menu',
        name:'菜单管理',
        component: () => import(/* webpackChunkName: "about" */ '@/views/authorityManage/menuManage/index.vue'),
      },
      { // 客服问答列表
          path: '/customer',
          name:'客服问答列表',
          component: () => import('@/views/customerService/index.vue'),
      },
      { // 样品类型管理
          path: '/foodTypeManage',
          name:'样品类型管理',
          component: () => import('@/views/foodTypeManage/index.vue'),
      },
      { // 样品类型检测项及其标准
          path: '/foodTypeManage/standards',
          name:'样品类型检测项及其标准',
          component: () => import('@/views/foodTypeManage/standards.vue'),
      },
      { // 数据大屏
        path:'/largeScreen',
        name:'大屏',
        component: () => import(/* webpackChunkName: "about" */ '@/views/largeScreen/index.vue'),
      },
      { // 资讯管理
        path:'/newsManage',
        name:'资讯管理',
        component: () => import('@/views/newsManage/index.vue'),
      },
    ]
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router;
