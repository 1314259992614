<template>
  <section>
    <router-view></router-view>
  </section>
</template>

<script>

  export default {
    name: 'App',
  }
</script>

<style lang="scss" >
  @import "@/static/default";
  @import "@/static/public";
  
  ::-webkit-scrollbar {/* 滚动条整体样式 */
      height: 8px; /* 宽高分别对应横竖滚动条的尺寸 */
      width: 8px;
      background:#fff;
      border-radius: 2px;
      // -webkit-box-shadow: inset 1px 0 1px rgba(0,0,0,0.2);
  }
  ::-webkit-scrollbar-thumb {/* 滚动条里的小方块 */
      border-radius: 4px;
      background: #c7c9cc;
      height: 120px;
  }
  ::-webkit-scrollbar-track {/* 滚动条里面的轨道 */
      -webkit-box-shadow: inset 0 0 2px #fff;
      border-radius: 1px;
      background:#fff;
  }
  // 分页组件样式
  .pagination{
    background: #fff;
  }
  .tangram-suggestion {
      z-index: 9999;
    }
  /deep/ .el-drawer__body{
   overflow:auto !important;
 }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    overflow:auto;
    height: 100%;
  }
  .el-scrollbar{
    padding-bottom: 11px !important;
  }
  /deep/ .el-drawer__open .el-drawer.rtl{
    overflow: auto !important;
  }
  div {
    box-sizing: border-box;
  }

  #manage-container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(240, 242, 245, 1);
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    overflow:auto;
  }

  .header-container {
    height: 82px !important;
    padding: 0 !important;
  }

  .main-container {
    width: 100%;
    height: 100%;
    padding: 24px !important;
    overflow:auto;
  }

  .nav-container {
    width: 208px !important;
    height: 100vh;
    overflow: hidden !important;
    background-color: $primary-color;
    box-shadow: 3px 0px 8px 0px rgba(0, 21, 41, 0.35);

    .nav-top {
      width: 100%;
      height: 23px;
      margin-bottom: 30px;
      margin-top: 29px;
      display: flex;
      justify-content: center;
      align-items: center;

      .el-image {
        width: 60%;
      }
    }

    .scroll-bar {
      width: 100%;
      overflow-x: hidden;
      height: calc(100vh - 23px - 59px);
    }
  }

  .el-scrollbar__wrap {
    overflow-x: hidden !important;
  }

</style>
