import router from '@/router/index'

const state = {
    userData:{},
    isMap:false,
    activePath:'/'
}
const mutations = {
    set: (state: any, view: any)=> {
        state.userData = view
    }
}

export default {
    namespaced: true,  // 这个是命名空间，用来区分不同的模块，我是根据文件的名字来区分
    state,
    mutations,
};
